



















































































import { Component, Vue, Watch } from "vue-property-decorator";
import api from "@/api";
import store from "@/store";
import { Message } from "element-ui";

@Component({})
export default class AUser extends Vue {
  // 字典
  config = store.state.system.config;
  // 表格数据
  tableData = [];
  // 加载状态
  loading = false;
  btnloading = false
  // 总条数
  total = 0;
  // 弹窗
  configDialog = false
  // 操作
  id: any = ''
  channel: any = {
    channelAuth: "sole_agency",
    province: "",
    productType: 'bond_bid',
    provinceCode: "",
    sendingOrganization: "",
    sendingOrganizationCode: "",
    tradingCenter: "",
    tradingCenterCode: ""
  }
  //上传
  multiple = false;
  uploadDialog = false;
  uploadId = "";
  uploadApi = "";
  upload(row: any) {
    this.uploadId = row.id;
  }
  success() {
    console.log("success");
    this.uploadDialog = false;
  }

  onInput0_100(e: any) {
    // this.$message.closeAll();
    // 验证是否是纯数字
    // let isNumber = /^\d*$/.test(e.target.value);
    let isNumber = /^[0-9]+.?[0-9]*$/.test(e.target.value);
    // 过滤非数字
    e.target.value = e.target.value.replace(/[^\d^\.]+/g, "")
            .replace(/^0+(\d)/, "$1")
            .replace(/^\./, "0.")
            .match(/^\d*(\.?\d{0,2})/g)[0] || "";;
    if (!isNumber || e.target.value < 0 || e.target.value > 100) {
      this.$message.warning("只能输入[0,100]区间的整数");
      e.target.value = ''
    }
    // e.target.value = (e.target.value >= 0 && e.target.value <= 100 && e.target.value.match(/^\d*/g)[0]) || null;
  }

  @Watch('channel.sendingOrganizationCode')
  getShiSuan() {
    if(this.channel.sendingOrganizationCode) {

    }
  }

  @Watch('channel.provinceCode')
  getTradingCenter() {
    // 根据省，筛选交易中心
    if(!this.channel.id) {
      this.channel.tradingCenterCode = ''
      this.channel.sendingOrganizationCode = ''
      this.org = []
      this.channeldict = []
    }
    this.channelDict()
  }

  @Watch('channel.tradingCenterCode')
  getSendingOrganization() {
    // 根据交易 筛选出函机构
    if(!this.channel.id) {
      this.channel.sendingOrganizationCode = ''
      this.org = []

    }
    this.getOrganization()
  }

  add() {
    this.channel = {
      channelAuth: "sole_agency",
      province: "",
      productType: 'bond_bid',
      provinceCode: "",
      sendingOrganization: "",
      sendingOrganizationCode: "",
      tradingCenter: "",
      tradingCenterCode: "",
      clearingForm: '',
      settlementRatio: ''
    }
    this.isDisabled = false
    this.configDialog = true
  }
  isDisabled = false
  edit(row: any) {
    this.isDisabled = true
    let r = {...row}
    console.log('rrow', r)
    this.channel = {
      id: r.id,
      channelAuth: r.channelAuth,
      province: r.province,
      productType: r.productType,
      provinceCode: r.provinceCode,
      sendingOrganization: r.sendingOrganization,
      sendingOrganizationCode: r.sendingOrganizationCode,
      tradingCenter: r.tradingCenter,
      tradingCenterCode: r.tradingCenterCode,
      clearingForm: r.clearingForm,
      settlementRatio: r.settlementRatio
    }
    console.log('aaaaaaar', this.channel.tradingCenterCode, r);
    this.configDialog = true
  }


  javaConfig: any = {}
  getConf() {
    api.getConf().then((res) => {
      this.javaConfig = res
    })
  }

  // 搜索条件
  search: any = {
    id: '',
    pagination: {
      page: 0, //页码，从0开始
      pageSize: 20, //每页数量
    },
  };
  // 搜索 search:true 重置翻页
  searchTable(search = false) {
    // this.loading = true;
    if (search) {
      this.search.pagination = {
        pageSize: 20,
        page: 0,
      };
    }
    api
      .post("J30110", this.search)
      .then((data) => {
        this.total = data.total;
        this.tableData = data.list;
      })
      .finally(() => {
        this.loading = false;
      });
  }

  // 保存
  saveLoading = false
  save() {
    this.saveLoading = true
    if(!this.isDisabled) {
      // 新增
      this.channel.province = this.province[this.channel.provinceCode]
      this.channel.sendingOrganization = this.org[this.channel.sendingOrganizationCode]
      this.channel.tradingCenter = this.channeldict[this.channel.tradingCenterCode]
    }

    let obj = {
      ...this.channel
    }

    if(!this.isDisabled) {
      // 新增
      obj.id = this.id
    } else {
      // 编辑

    }

    api
      .post(!this.isDisabled ? 'J30111' : 'J30125', obj)
      .then(data => {
        this.$message.success('操作成功')
        this.configDialog = false
        this.searchTable()
        this.channeldict = []
        this.channel = {
          channelAuth: "sole_agency",
          province: "",
          productType: 'bond_bid',
          provinceCode: "",
          sendingOrganization: "",
          sendingOrganizationCode: "",
          tradingCenter: "",
          tradingCenterCode: ""
        }
      }).finally(() => {
        this.saveLoading = false
    })

  }

  // 获取出函机构
  org: any = {}
  getOrganization(){
    if(!this.channel.tradingCenterCode) {
      return
    }
    api
      .post('A13006', {bond_type: this.channel.productType, channel_no: this.channel.tradingCenterCode, area_id: this.channel.provinceCode}).then(res => {
        this.org = res
      })
  }

  // 获取省份
  province: any = {}
  getProvince() {
    api
      .post('A13004')
      .then(res => {
        this.province = res
      })
  }

  // 交易中心
  channeldict: any = {}
  channelDict() {
    api
      .post('A13005', {area_id: this.channel.provinceCode})
      .then(res => {
        this.channeldict = res
      })
      .catch((err: any) => {
        console.log(err)
      })
  }

  // 删除
  del(id: any) {
    this.$confirm(
      "禁用后该渠道将无法再获取对应产品数据，请确认是否禁用?",
      "提示",
      {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }
    ).then(() => {
      this.btnloading = true;
      api
        .post("J30112", { id: id })
        .then(() => {
          this.searchTable();
          Message.success("操作成功");
        })
        .finally(() => {
          this.btnloading = false;
        });
    });
  }

  activated() {
    let { id, channelType } = this.$route.query
    this.search.id = id
    this.id = id
    this.channel.channelType = channelType
    this.searchTable();
    this.getProvince()
    this.getConf()

  }

}
